import Heading from '../../core/typography/Heading';
import CollectEmail, {
  SuccessComponent,
  TermsComponent,
} from '../CollectEmail/CollectEmail';
import React from 'react';

export const WelfareStateLeadGenerationForm = ({
  eventAction,
}: {
  eventAction: string;
}) => {
  return (
    <div className="w-full text-center">
      <Heading variant="h4" className="text-center">
        Il consiglio di un esperto può aiutarti!
      </Heading>
      <Heading
        variant="h6"
        className="text-paragraph text-center mb-8 font-normal"
        style={{
          fontSize: '17px',
          lineHeight: '32px',
          letterSpacing: '-0.24px',
          fontWeight: '400',
        }}
      >
        Se vuoi approfondire <b>l’indennità di accompagnamento</b> e soddisfare
        dubbi e curiosità, sei nel posto giusto! Lasciaci una e-mail e ti
        metteremo in contatto con un esperto per una prima{' '}
        <b>consulenza gratuita</b>.
      </Heading>
      <CollectEmail eventAction={eventAction} autoConsent type="welfare_lead">
        <TermsComponent>
          <p className="text-center">
            Cliccando sul pulsante dichiari di aver letto la nostra{' '}
            <a
              className="underline"
              href="https://assets.ctfassets.net/sqy8sbi9hlp9/5KdR3rN4FCdWX7XzOw3cIn/0cd27992366c5a3405103b329bc94446/Hellougo_Indennita__Accompagnamento.pdf"
              target="_blank"
              rel="noreferrer"
            >
              informativa
            </a>{' '}
            privacy e acconsenti al trasferimento dei dati da UGO al
            professionista che si occuperà della consulenza.
          </p>
        </TermsComponent>
        <SuccessComponent>
          <Heading variant="h5" className="mb-0 text-center">
            Grazie per il tuo contatto.
          </Heading>
          <p className="text-paragraph text-center">
            Ti abbiamo inviato una e-mail!
          </p>
        </SuccessComponent>
      </CollectEmail>
    </div>
  );
};
